export const baseUrl = process.env.REACT_APP_BASE_URL;

// urls
export const loginPost = "api/admin/login";
export const verifyToken = "api/adminverifyToken";
export const MoviesPost= "api/admin/addMovies";
export const getMovies= "api/admin/movieList";
export const deleteMovie = "api/admin/deleteMovie";
export const getUsers = "api/admin/getAllUsers";
export const searchUser = "api/admin/searchUser";
export const Userblock = "api/admin/block"; 
export const Userunblock = "api/admin/unblock"; 
export const getAllTheaters = "api/admin/getAllTheaters"; 
export const rejectTheater = "api/admin/rejectTheater"; 
export const approveTheater = "api/admin/approveTheater"; 
export const getAllTheater = "api/admin/getAllTheater";
export const getMovie = "api/admin/getMovie"; 
export const movieEditPost = "api/admin/editMovie";
export const PosterPost= "api/admin/addPoster";
export const getAllPoster= "api/admin/getAllPoster";
export const deletePoster = "api/admin/deletePoster"; 
export const getOneTheater = "api/admin/getOneTheater";
export const deleteGenre= "api/admin/deleteGenre";
export const addgenrepost = "api/admin/addgenre";
export const getgenre= "api/admin/getgenre";
export const editGenre= "api/admin/editGenre";
export const getGenreone = "api/admin/getGenreone"; 
export const getOnePaymentDetails = "api/admin/getOnePaymentDetailss";
export const ReservationDetails = "api/admin/ReservationDetailss"; 
export const getOneDayRevenue = "api/admin/getOneDayRevenue";
export const reservationDetails = "api/admin/reservationDetails";
export const getDailyRevenue = "api/admin/getDailyRevenue";
export const getAllUserss = "api/admin/getAllUserss"; 
export const readTrue = "api/admin/readTrue";
export const getUnreadMEssageAllTheater = "api/admin/getUnreadMEssageAllTheater"; 
export const notificationCountAdmin = "api/admin/notificationCountAdmin"; 
export const getUnrededMessage = "api/admin/getUnrededMessage";
export const addmsg = "api/message/addmsg";
export const getmsg = "api/message/getmsg";
export const latestMessage = "api/admin/latestMessage";